<template>
	<div>
		<el-tabs v-model="activeName" :before-leave="beforeLeave" :type="type" @tab-click="tabClick">
			<el-tab-pane :label="item.name" :name="item.id" v-for="(item,index) in list" :key="index">
			</el-tab-pane>
			<slot></slot>
		</el-tabs>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。

	export default {
		data() {
			return {
				activeName: '',

			}
		},
		props: {
			type: {
				type: String,
				default: () => {
					return ''
				}
			},
			list: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		methods: {
			tabClick(e){
				this.$emit('tabClick',e)
			},
			// tabr栏切换时触发
			beforeLeave: function(news, old) {
				this.$emit('beforeLeave', {
					news,
					old
				})
			},
			set(activeName) {
				this.activeName = activeName
			},
		},
		components: {

		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	/deep/ .is-active {
		color: @primaryColorQian  !important;
	}

	/deep/ .el-tabs__item:hover {
		color: @primaryColorQian  !important;
	}

	/deep/ .el-tabs__active-bar {
		background-color: @primaryColorQian  !important;
	}
</style>
