<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>设置密码</span>
			</div>
			<sb-tab @beforeLeave="beforeLeave" ref="tab" :list="tabList"></sb-tab>
			<!-- 设置密码 -->
			<div class="cardBox" v-show="activeName == 0">
				<div class="top">
					<div class="iphoneTitle">绑定手机号：</div>
					<div class="iphone">{{form.mobile}}</div>
				</div>
				<el-form ref="formRules" :model="form" label-width="120px" :rules="formRules" size="medium">
					<el-form-item label="填写验证码：" required prop="code">
						<div class="sendCodeBox">
							<el-input v-model="form.code" maxlength="6" placeholder="验证码" style="width: 122px;" autocomplete="new-password"></el-input>
							<div class="sendCOde" @click="sendCode">{{timeNum?`${timeNum}S后`:'获取验证码'}}</div>
						</div>
					</el-form-item>
					<el-form-item
						label="新密码："
						prop="newPwd"
						:rules="[
								{required:true,validator:validatePass1,trigger:'blur'}
						]">
						<el-input v-model="form.newPwd" placeholder="请输入新密码" style="width: 248px;" show-password autocomplete="new-password"></el-input>
					</el-form-item>
					<el-form-item
							label="确认新密码："
							required
							:rules="[
								{required:true,validator:validatePass2,trigger:'blur'}
							]"
							prop="newPwd1">
						<el-input v-model="form.newPwd1 " placeholder="请输入确认新密码" style="width: 248px;" show-password autocomplete="new-password"></el-input>
					</el-form-item>
				</el-form>
				<div class="submitBox">
					<div class="sendCOde submit" @click="updatePWD">保存</div>
				</div>
			</div>
			<!-- 设置支付密码 -->
			<!--注释到支付密码 还没做的功能-->
			<div class="cardBox" v-show="activeName == 1" v-if="false">
				<div class="top">
					<div class="iphoneTitle">绑定手机号：</div>
					<div class="iphone">186****5711</div>
				</div>
				<el-form ref="payForm" :model="payForm" label-width="120px" :rules="payFormRules">
					<el-form-item label="填写验证码：" required prop="code">
						<div class="sendCodeBox">
							<el-input v-model="payForm.code" placeholder="验证码" style="width: 122px;"></el-input>
							<div class="sendCOde" @click="sendCode">获取验证码</div>
						</div>
					</el-form-item>
					<el-form-item label="新密码：" required prop="code">
						<el-input v-model="payForm.code" placeholder="请输入新密码" style="width: 248px;"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码：" required prop="code">
						<el-input v-model="payForm.code" placeholder="请输入确认新密码" style="width: 248px;"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import SbTab from '../../components/tab/tab.vue'
	import { SMS, UPDATEPWD,} from '../../api/base'
	export default {
		data() {
			var validatePass1 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'));
				} else if (value.length <6) {
					callback(new Error('请输入至少6位数密码'));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.form.newPwd) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				tabList:[
					{
						name:'设置登录密码',
						id:'0',
					},
					// {
					// 	name:'设置支付密码',
					// 	id:'1',
					// }
				],
				activeName:0,
				form:{
					code:'',
					newPwd:'',
					newPwd1:'',
				},
				sendForm:{
					mobile:'',
				},
				payForm:{
					code:'',
				},
				validatePass1,
				validatePass2,
				payFormRules:{
					code: [{
							required: true,
							message: '请输入手机验证码',
							trigger: 'blur'
						},
					],
				},
				formRules:{
					code: [
						{
							required: true,
							message: '请输入手机验证码',
							trigger: 'blur'
						},
					],
				},
				times:{
					sendTime:'',
					newTime:'',
				},
				timeNum:0,
				disabled:false,
			}
		},
		methods: {
			setTimeOf(sendTime){
				this.disabled = true;
				this.times.sendTime = sendTime || new Date().getTime();
				localStorage.setItem('times',this.times.sendTime);
				const that = this;
				const $set = setInterval(()=>{
					that.times.newTime = new Date().getTime();
					const cha = that.times.newTime - that.times.sendTime;
					that.timeNum = (cha/1000).toFixed(0)
					if(cha>60000){
						clearInterval($set);
						this.disabled = false;
						this.timeNum = 0
						localStorage.removeItem('times');

					}
				},1000)
			},
			// tabr栏切换时触发
			beforeLeave:function({news}){
				// console.log(news,old)
				this.activeName = news;
				if(news == 0){
					if(this.$refs.payForm){
						this.$refs.payForm.resetFields()

					}
				}else{
					if(this.$refs.loginForm){
						this.$refs.loginForm.resetFields()
					}
				}
			},
			updatePWD(){
				this.$refs.formRules.validate((validate)=>{
					if(validate){
						this.$axios.post(UPDATEPWD,this.form).then(resp=>{
							if(resp.code == 200){
								this.$mes({message:resp.msg});
								this.form.code = '';
								this.form.newPwd = '';
								this.form.newPwd1 = '';
							}
						})
					}
				})
			},
			sendCode:function(){
				console.log(this.sendForm)
				if(!this.disabled){
					this.$axios.post(SMS,this.sendForm).then(resp=>{
						if(resp.code == 200){
							this.$mes({message:resp.msg});

							this.setTimeOf()
						}
					})
				}

			},
		},
		components: {
			SbTab,
		},
		watch: {
			// data(newValue, oldValue) {
				
			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		mounted() {
			this.$refs.tab.set('0');
			const mobile = this.$store.getters['setting/userInfo'].account;
			this.sendForm.mobile = mobile;
			this.form.mobile = mobile.replace(mobile.substring(3,7),'****');
			//判断是否刷新页面
			const times = localStorage.getItem('times');
			console.log(times)
			if(times != null){
				const nowTime = new Date().getTime();
				console.log(nowTime-times)
				if((nowTime-times)<60000){
					this.setTimeOf(times)
				}
			}
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style lang="less" scoped>
	.submitBox{
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.submit{
		text-align: center;
		width: 198px !important;
	}
	.sendCodeBox{
		display: flex;
		align-items: center;
	}
	.sendCOde{
		width: 112px;
		border-radius: 5px;
		line-height: 40px;
		height: 40px;
		background-color: @primaryColorQian;
		color: white;
		border: 1px solid @primaryColorQian;
		text-align: center;
		margin-left: 15px;
		cursor: pointer;
	}
	.sendCOde:hover{
		background-color: @primaryColorQianHover !important;
		border: 1px solid @primaryColorQianHover !important;
	}
	.cardBox{
		width: 368px;
		margin: 0 auto;
		padding: 80px 0;
		.el-form-item{
			margin-bottom: 40px;
		}
	}
	.top{
		margin-bottom: 40px;
		.iphoneTitle{
			display: inline-block;
			width: 120px;
			text-align: right;
			box-sizing: border-box;
			padding-right: 12px;
		}
		.iphone{
			display: inline-block;
		}
	}
	.box-card {
		width: 980px;
	}
</style>
